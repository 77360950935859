.text-block,
.remove-last-child-margin {
  > * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &.row {
    > * {
      &.col,
      &[class^="col-"] {
        &:last-child {
          > * {
          	margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.text-xs {
  font-size: $font-size-xs !important;
}

.text-sm {
  font-size: $font-size-sm !important;
  line-height: 1.6;
}

.text-lg {
  @include media-breakpoint-up(layout-flip) {
    font-size: $font-size-lg !important;
    line-height: 1.6;
    letter-spacing: -0.01em;
  }
}

.text-xl {
  font-size: 16px !important;

  @include media-breakpoint-up(md) {
    font-size: $font-size-xl !important;
  }
}

.text-xxl {
  font-size: 16px !important;

  @include media-breakpoint-up(md) {
    font-size: $font-size-xxl !important;
  }
}

.text-gray-400 {
  color: $gray-400 !important;
}

.text-gray-700 {
  color: $gray-700 !important;
}

.text-teal-dark {
  color: $teal-dark !important;
}

.text-green-dark-2 {
  color: $green-dark-2 !important;
}

.text-green-dark-3 {
  color: $green-dark-3 !important;
}

.text-xxs-green-dark-2 {
  @include media-breakpoint-up(xxs) {
    color: $green-dark-2 !important;
  }
}

.text-blue-dark {
  color: $blue-dark !important;
}

.text-blue-deep {
  color: $blue-deep !important;
}

.bg-teal-light {
  background-color: $teal-light !important;
}

.bg-green-dark-3 {
  background-color: $green-dark-3 !important;
}

.bg-xxs-light-mint {
  @include media-breakpoint-up(xxs) {
    background-color: $light-mint !important;
  }
}

.bg-gray-110 {
  background-color: $gray-110 !important;
}

.bg-blue-snow {
  background-color: $blue-snow !important;
}

.bg-blue-lake-ice {
  background-color: $blue-lake-ice !important;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

strong {
  font-weight: $font-weight-bold;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {

}

h2, .h2 {

}

h3, .h3 {

}

h4, .h4 {
  font-weight: $font-weight-medium;
}

h5, .h5 {
  font-weight: $font-weight-medium;
}

h6, .h6 {
  font-weight: $font-weight-medium;
}

.fw-base {
  font-weight: $body-font-weight !important;
}

.fw-medium {
  font-weight: $font-weight-medium !important;
}

.fw-black {
  font-weight: $font-weight-black !important;
}

.default-typography {
  h1, .h1 {
    @include font-size(50px);
  }

  h2, .h2 {
    @include font-size(40px);

    @include media-breakpoint-down(xxs) {
      font-size: 22px;
    }
  }

  h3, .h3 {
    @include font-size(32px);

    @include media-breakpoint-down(xxs) {
      font-size: 20px;
    }
  }

  h4, .h4 {
    @include font-size(24px);

    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
  }

  h5, .h5 {
    @include font-size(20px);
  }

  h6, .h6 {
    @include font-size(16px);
  }
}

.list-with-check {
  font-size: 14px;
  margin-bottom: 25px;

  @include media-breakpoint-up(xxs) {
    font-size: 16px;
  }

  @include media-breakpoint-up(xs) {
    padding-left: 10px;
  }

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }

  li {
    padding-left: 1.8em;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 24 24' viewBox='0 0 24 24'><path d='M12.5 0c6.6 0 12 5.4 12 12s-5.4 12-12 12-12-5.4-12-12 5.4-12 12-12z' shape-rendering='crispEdges' style='fill:#{$primary}' transform='translate(-.5)'/><path d='m7.9 11.7 3.5 3.5 5.7-5.7' style='fill:none;stroke:#fffefb;stroke-width:2;stroke-linecap:round;stroke-linejoin:round' transform='translate(-.5)'/></svg>"));
    background-repeat: no-repeat;
    background-size: 1.2em;
    background-position: left 0 top 0.15em;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}