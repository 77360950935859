@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	border-radius: 100vmax;
	cursor: pointer;

	svg path {
		fill: #ffffff;
	}

	&.withText {
		right: -24px;
		bottom: -16px;
		padding: 10px 16px;
		color: $green-dark;
		border-radius: 8px;
		transition: all 0.3s ease-in-out 0s;

		&:hover,
		&:focus {
			background: #22c7a0;
		}

		@include media-breakpoint-down(layout-flip) {
			padding: 5px 8px;
		}

		.availableStatus {
			margin: 0 0 0 4px;
			font-weight: 600;
			font-size: 13px;

			@include media-breakpoint-down(md) {
				font-size: 10px;
			}
		}

		svg path {
			fill: $green-dark;
		}
	}
}

.available {
	background-color: $primary;
}

.wait {
	background-color: #ffda79;
}

.offline {
	background-color: #fe4949;
}
