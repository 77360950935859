@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.sectionProgress {
	display: flex;
	align-items: center;
	min-height: 100vh;
	background-color: $blue-snow;
	padding: 30px 0;
	color: $blue-dark;
	text-align: center;

	@include media-breakpoint-up(layout-flip) {
		padding: 55px 0;
	}

	@include media-breakpoint-up(xl) {
		padding: 80px 0;
	}

	&__progressBlock {
		position: relative;
		max-width: 660px;
		margin: 0 auto 30px;
		background-color: $gray-180;
		border-radius: 100rem;
		width: 100%;
		height: 13px;

		@include media-breakpoint-up(sm) {
			margin-bottom: 45px;
		}
	}

	&__progressStrip {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		border-radius: 100rem;
		background-color: $blue-deep;
	}

	&__description {
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;
	}

	&__inputAndButtonBlock {
		max-width: 400px;
		margin: 0 auto;
	}
}