@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.listingHeader {
  background-color: #EFF8FE !important;
  padding: 15px 0;

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    padding: 25px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 35px 0;
  }

  &__logo {
    display: flex;
    width: 140px;

    @include media-breakpoint-up(md) {
      width: 180px;
    }

    @include media-breakpoint-up(lg) {
      width: 210px;
    }

    > img {
      width: 100%;
      height: auto;
    }
  }
}

.hideHeader {
  @include media-breakpoint-down(layout-flip) {
    z-index: 0;
  }
}
