@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.search {
  @include media-breakpoint-up(sm) {
    display: flex;
    max-width: 560px;
  }

  &__input {
    margin-bottom: 8px;

    @include media-breakpoint-up(sm) {
      flex-grow: 1;
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  &__btn {
    border-radius: $input-border-radius !important;
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}

.videoIframeHolder {
  @include responsiveRatio(16, 9);

  position: relative;
  background-color: $secondary;
  border: 1px solid $secondary;
  border-radius: 4px;
  overflow: hidden;
}

.cardContactOptions {
  position: relative;
  background-color: $green-dark-5;
  border-radius: 10px;
  box-shadow: 1px 3px 12px 0px rgba($black, 0.25);
  padding: 24px 24px 24px 70px;
  color: $gray-170;
  width: 100%;
  margin-bottom: 12px;

  @include media-breakpoint-up(xxs) {
    background-color: $white;
    color: $green-dark-4;
    text-align: center;
    margin-bottom: 20px;
    padding-left: 24px;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 55px;
  }

  &__icon {
    display: flex;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 20px;

    @include media-breakpoint-down(xxs) {
      position: absolute;
      top: 24px;
      left: 16px;
      z-index: 1;
    }

    @include media-breakpoint-up(xxs) {
      justify-content: center;
      font-size: 64px;
    }
  }
}

.cardBenefits {
  position: relative;
  background-color: $white;
  border: 1px solid $green-dark-3;
  border-radius: 10px;
  padding: 14px 14px 24px 14px;
  color: $secondary;
  margin-bottom: 16px;
  width: 100%;

  @include media-breakpoint-down(xxs) {
    height: 160px;  
}

  @include media-breakpoint-up(sm) {
    background-color: $green;
    box-shadow: 1px 3px 16px 0px rgba($black, 0.25);
    border: none;
    padding: 16px 24px 16px 104px;
    color: $white;
  }

  @include media-breakpoint-up(md) {
    padding: 32px 16px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__icon {
    display: inline-flex;
    font-size: 40px;
    margin-bottom: 5px;
    line-height: 1;
    color: $green-dark-3;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 24px;
      left: 24px;
      z-index: 5;
      font-size: 64px;
      margin-bottom: 15px;
      color: $white;
    }

    @include media-breakpoint-up(md) {
      position: static;
    }
  }
}

.quoteBlock {
  padding: 40px 24px;
  border-radius: 10px;
  background-color: $green-dark-6;
  color: $white;
  box-shadow: 1px 3px 16px 0px rgba($black, 0.25);
  margin-bottom: 30px;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 48px 32px;
    margin-bottom: 55px;
    text-align: left;
  }
}
.bottomQuote {
  color: #FFFEFB;
  font-family: Mulish;
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 155%;
}