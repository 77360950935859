@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.search {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: $input-padding;
	background: $white;
	border: $input-border-width solid $input-border-color;
	border-radius: $input-border-radius;
	height: calc($btn-height - 10px);
	transition: color 0.3s, border-color 0.3s, background-color 0.3s;

	@include media-breakpoint-up(md) {
		height: $input-height;
	}

	&:hover,
	&:focus,
	&.active {
		border-color: $input-hover-border-color;
	}

	&__input {
		padding: 0;
		width: calc(100% - 40px);
		font-size: $input-font-size;
		line-height: 19px;
		border: none;
		background: transparent;
		outline: none;
		color: $input-color;
		transition: all 0.2s ease-out;

		@include placeholder {
			color: $input-placeholder-color;
		};
	}

	&__icon {
		@include sizeElement(20px);

		cursor: pointer;
		margin-right: 10px;
	}

	&__selected {
		padding: 10px;
		background: #3ceac1;
		border-radius: 50px;
		color: $white;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		white-space: nowrap;
	}
}