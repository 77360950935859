@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.layout {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	padding: 0 calc((100% - 1200px) / 2);

	.main {
		position: relative;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}