@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.homeLayout {
	padding: 0;
	background: linear-gradient(180deg, #E5FBF8 0%, #ECFDF9 100%);

}

.homeLayoutConnect {
	padding: 0;
	background-color: #EFF8FE !important;
}