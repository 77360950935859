@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

main {
    background-color: #EFF8FE !important;
}

.sectionProgress {
	background-color: $blue-snow;
	color: $blue-dark;
}