.button {
	display: flex;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background-color: #21816A;
	border: #21816A;
	}

// .iconWrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-shrink: 0;
//   width: 34px;
//   height: 34px;
//   background: #3ceac1;
//   box-shadow: 0 10px 15px rgba(69, 199, 184, 0.31);
//   border-radius: 50px;
//   margin-right: 10px;
//   transition: all 0.3s ease-in-out 0s;
// }

.text {
	color: #FAFAFA;
	font-family: Mulish;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 125%; /* 17.5px */
	letter-spacing: 0.14px;
}
