@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.wrapper {
	padding: 0;
	background: linear-gradient(180deg, #E5FBF8 0%, #ECFDF9 100%);

}

.listing {
	position: relative;
	z-index: 3;

	@include media-breakpoint-down(xl) {
		padding: 0 24px;
	}

	@include media-breakpoint-down(md) {
		padding: 80px 24px 0;
	}

	@include media-breakpoint-down(layout-flip) {
		padding: 80px 8px 0;
	}
}
