@import "../../styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.16px;
	margin: 16px 0;
	color: #162521;
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	@include media-breakpoint-up(layout-flip) {
		font-size: 17px;
	}

	& + & {
		margin-top: 8px;
	}

	&:first-child {
		margin-top: 0;
	}
}

.licenseIcon {
	width: 24px;
	height: 24px;
	object-fit: contain;
	margin-right: 8px;
}
