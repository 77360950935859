@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
	position: relative;
	display: inline-block;
	max-width: 177px;
	width: 100%;
	flex-shrink: 0;
	margin-bottom: 10px;
	border-radius: 10px;

	@include media-breakpoint-up(layout-flip) {
		border-radius: 30px;
	}

	.icon {
		@include clean-btn;

		position: absolute;
		right: 6px;
		bottom: 6px;
		z-index: 5;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		background: #3ceac1;
		border-radius: 4px;

		@include media-breakpoint-up(xs) {
			display: none;
		}
	}

	.verified {
		position: absolute;
		top: 0;
		right: 0;
		height: auto;

		:global .verified-circle__icon {
			margin: 0;
		}
	}
}

.image {
	@include absoluteCover;

	object-fit: fill;
	border-radius: 10px;
	
}

.fill {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	object-fit: contain;
}
.fill img {
	flex-shrink: 0;
	min-width: 100%;
	min-height: 100%
}

.badge {
	position: absolute;
	bottom: -8px;
	right: -16px;
}
