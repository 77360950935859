@use "sass:map";

// MIXINS

@import "node_modules/bootstrap/scss/mixins/breakpoints";
@import "./variables";

// Placeholder stylization ------------------------------------
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// EXAMPLE:
// @include placeholder {
// 	color: red;
// };

// Creation border-arrows ------------------------------------
@mixin borderArrow($width, $height, $bg, $direction) {
  width: 0;
  height: 0;
  border-style: solid;

  @if $direction == t {
    border-width: 0 calc($width / 2) + px $height + px calc($width / 2) + px;
    border-color: transparent transparent $bg transparent;
  }

  @if $direction == r {
    border-width: calc($height / 2) + px 0 calc($height / 2) + px $width + px;
    border-color: transparent transparent transparent $bg;
  }

  @if $direction == b {
    border-width: $height + px calc($width / 2) + px 0 calc($width / 2) + px;
    border-color: $bg transparent transparent transparent;
  }

  @if $direction == l {
    border-width: calc($height / 2) + px $width + px calc($height / 2) + px 0;
    border-color: transparent $bg transparent transparent;
  }

  @if $direction == tl {
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent;
  }

  @if $direction == tr {
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent;
  }

  @if $direction == br {
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent;
  }

  @if $direction == bl {
    border-width: $height + px 0 0 $width + px;
    border-color: transparent transparent transparent $bg;
  }
}

// EXAMPLE: @include borderArrow(20, 20, #FF0000, t);

// Adding fonts ------------------------------------
@mixin fontFace($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.woff2') format('woff2'), font-url('#{$file-path}.woff') format('woff'), font-url('#{$file-path}.ttf') format('truetype');
    }
    @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype');
    }
  }
}

// EXAMPLE:

// @include font-face("Roboto", "../fonts/Roboto/Roboto-Regular", 400);
// @include font-face("Roboto", "../fonts/Roboto/Roboto-Italic", 400, italic);
// @include font-face("Roboto", "../fonts/Roboto/Roboto-Bold", 700);

// Cover background image ------------------------------------
@mixin bgCover() {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// EXAMPLE: @include bgCover;

// Contain background image ------------------------------------
@mixin bgContain() {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

// EXAMPLE: @include bgContain;

// Flex center alignment ------------------------------------
@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

// EXAMPLE: @include flexCenter;

// Inline-flex center alignment ------------------------------------
@mixin inlineFlexCenter() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// EXAMPLE: @include inlineFlexCenter;

// Position absolute center alignment ------------------------------------
@mixin absoluteCenter($position: null) {
  position: absolute;

  @if $position == "v" {
    top: 50%;
    transform: translate(0, -50%);
  } @else if $position == "h" {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if $position == null {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// EXAMPLE: @include absoluteCenter;

// Absolute cover ------------------------------------
@mixin absoluteCover() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

// EXAMPLE: @include absoluteCover;

// Size of element ------------------------------------
@mixin sizeElement($width, $height: $width) {
  width: $width;
  height: $height;
}

// EXAMPLE: @include sizeElement(50px);

// Default pseudo-element ------------------------------------
@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

// EXAMPLE: div:after {@include pseudo; top: 100px; left: 100px; width: 200px; height: 200px;}

// Responsive ratio block through bottom padding ------------------------------------
@mixin responsiveRatio($x, $y, $pseudo: true) {
  $padding: unquote( ( calc($y / $x) ) * 100 + '%' );

  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);

      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-bottom: $padding;
  }
}

// EXAMPLE: @include responsiveRatio(16, 9);

// Preventing Overflow with Ellipsis ------------------------------------
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// EXAMPLE: @include ellipsis;

// Responsive images ------------------------------------
@mixin imgRes {
  max-width: 100%;
  height: auto;
}

// EXAMPLE: @include imgRes;

// Gray image ------------------------------------
@mixin gray {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)";
}

// EXAMPLE: @include gray;

// Gray none ------------------------------------
@mixin grayZero {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

// EXAMPLE: @include grayZero;

// Media queries for max width ------------------------------------
@mixin max-w($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media only screen and (max-width: #{map-get($grid-breakpoints, $breakpoint) - 0.02px}) {
      @content;
    }
  }

  @else {
    @media only screen and (max-width: $breakpoint + "px") {
      @content;
    }
  }
}

// EXAMPLE: @include max-w(md) {font-size: 50px;}

// Media queries for min width ------------------------------------
@mixin min-w($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media only screen and (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @media only screen and (min-width: $breakpoint + "px") {
      @content;
    }
  }
}

// EXAMPLE: @include min-w(md) {font-size: 50px;}

// Media queries for max height ------------------------------------
@mixin max-h($height) {
  @media only screen and (max-height: $height + "px") {
    @content;
  }
}

// EXAMPLE: @include max-h(800) {font-size: 50px;}

// Media queries for min height ------------------------------------
@mixin min-h($height) {
  @media only screen and (min-height: $height + "px") {
    @content;
  }
}

// EXAMPLE: @include min-h(800) {font-size: 50px;}

// Keyframes animation with delay between iterations ------------------------------------
@mixin waitAnimate($options: ()) {

  $options: map-merge((
    animationName: waitAnimate,
    duration: 1,
    waitTime: 0,
    timingFunction: linear,
    iterationCount: infinite
  ), $options);

  $name: map-get($options, animationName);
  $kf: map-get($options, keyframes);
  $kfLength: length($kf);
  $duration: map-get($options, duration);
  $waitTime: map-get($options, waitTime);
  $timingFunction: map-get($options, timingFunction);
  $iterationCount: map-get($options, iterationCount);
  $counter: 1; // index of 'each'

  @keyframes #{$name} {
    @each $frame, $prop in $kf {
      #{$frame * calc($duration / ($duration + $waitTime))}% {
        @each $k, $v in $prop {
          #{$k}: #{$v}
        }
      }
      // if last in loop and waitTime is not 0, add the last frame as 100% (this is what creates the pause)
      @if $counter == $kfLength and $waitTime > 0 {
        100% {
          @each $k, $v in $prop {
          	#{$k}: #{$v}
          }
        }
      }
      $counter: $counter+1;
    }
  }

  .#{$name} {
    animation: #{$name} #{$duration + $waitTime}s #{$timingFunction} #{$iterationCount};
  }
}

// EXAMPLE:

//@include waitAnimate(
//	(
//		animationName: animName,
//		keyframes: (
//			0: (
//				transform: scale(1),
//				background-color: blue
//			),
//			50: (
//				transform: scale(2),
//				background-color: green
//			),
//			100: (
//				transform: scale(3),
//				background-color: red
//			)
//		),
//		duration: 2,
//		waitTime: 1,
//		timingFunction: ease,
//		iterationCount: infinite
//	)
//);
//
//then add .animName class to your element

// HEADINGS FONT SIZE ------------------------------------
@mixin titleSizes($with-rem: false) {
  @each $media, $titles-size in $title-palette {
    @if $media == xs {
      @each $title, $size in $titles-size {
        #{$title},
        .#{$title} {
          @if $with-rem {
          	$value: $size + 0px;
          	font-size: rem($value);
          } @else {
          	font-size: $size + px;
          }
        }
      }
    } @else {
      @include min-w(#{$media}) {
        @each $title, $size in $titles-size {
          #{$title},
          .#{$title} {
          	@if $with-rem {
          		$value: $size + 0px;
          		font-size: rem($value);
          	} @else {
          		font-size: $size + px;
          	}
          }
        }
      }
    }
  }
}

// @include titleSizes();

// CUSTOM SCROLLBAR ------------------------------------
@mixin customScrollbar($scrollbar-size, $scrollbar-track-bg, $scrollbar-thumb-bg, $scrollbar-thumb-border-radius: 0) {
  &::-webkit-scrollbar-button {
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-bg;

    &:active {
      background-color: lighten($scrollbar-track-bg, 5%);
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $scrollbar-thumb-border-radius;
    background-color: $scrollbar-thumb-bg;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      background-color: lighten($scrollbar-thumb-bg, 5%);
    }

    &:active {
      background-color: darken($scrollbar-thumb-bg, 5%);
    }
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-image: $scrollbar-size;
  }

  &::-webkit-resizer {
    background-repeat: no-repeat;
    background-color: $scrollbar-track-bg;
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  // FOR INTERNET EXPLORER
  & {
    scrollbar-face-color: $scrollbar-thumb-bg;
    scrollbar-track-color: $scrollbar-track-bg;
    scrollbar-shadow-color: $scrollbar-thumb-bg;
    scrollbar-arrow-color: $scrollbar-thumb-bg;
  }

  // FOR FIREFOX
  & {
    scrollbar-width: auto;
    scrollbar-color: $scrollbar-thumb-bg $scrollbar-track-bg;
  }
}

// EXAMPLE: @include customScrollbar(10px, red, green, 5px);

// LIST RESET ------------------------------------
@mixin listReset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// @include listReset;

// WORD WRAP ------------------------------------
@mixin wordWrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// @include wordWrap;

// VERTICAL TEXT TOP BOTTOM ------------------------------------
@mixin verticalTextTopBottom() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// @include verticalTextTopBottom;

// VERTICAL TEXT BOTTOM TOP ------------------------------------
@mixin verticalTextBottomTop() {
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: inline-block;
  margin: 0;
}

// @include verticalTextBottomTop;

// VISUALLY HIDDEN ------------------------------------
@mixin visuallyHidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

// @include visuallyHidden;

// ADD GRID GUTTER ------------------------------------
@mixin addGridGutter($gutter) {
  &.gutter-#{$gutter} {
    margin-left: -$gutter * 0.5 + px;
    margin-right: -$gutter * 0.5 + px;

    > .col,
    > [class^="col"] {
      padding-left: $gutter * 0.5 + px;
      padding-right: $gutter * 0.5 + px;
    }
  }
}

// @include addGridGutter(12);

@mixin max-bg {
  @media (min-width: 3000px) {
    @content;
  }
}

@mixin restrict-prop($prop, $val) {
  #{$prop}: $val;
  min-#{$prop}: $val;
  max-#{$prop}: $val;
}

@mixin clean-btn {
  outline: none !important;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  justify-content: center;

  &:disabled {
    cursor: default;
  }
}

@mixin layout-flip {
  @media (max-width: #{$layout-flip-var}) {
    @content;
  }
}

@mixin min-layout-flip {
  @media (min-width: #{$layout-flip-var}) {
    @content;
  }
}
