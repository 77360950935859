@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
	position: relative;
}

.options {
	position: absolute;
	z-index: 2000;
	left: 0;
	right: 0;
	margin-top: 8px;
	padding: 6px 0 6px 0;
	min-height: 250px;
	background: #ffffff;
	border: 1px solid $gray-200;
	box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
	border-radius: 4px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #6e7891;
	box-sizing: content-box;
	overflow-y: auto;

	@include media-breakpoint-down(layout-flip) {
		max-height: 200px;
	}
}

.item {
	padding: 26px 20px;
	border: none;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #6e7891;
	cursor: pointer;

	&:hover {
		background: $light-mint;
	}
}
