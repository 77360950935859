@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
  margin: 0;
  padding: 0;
  list-style: none;
}

.title {
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0 16px;
  color: #1f342f;

  @include media-breakpoint-down(layout-flip) {
    margin: 0 0 16px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

.text {
  margin: 8px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #68768b;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}

.item {
  &:not(:last-child) {
    margin: 0 0 32px;
  }
}
