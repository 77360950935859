// COLORS
$white:                      #FFFFFF !default;
$gray-100:                   #F4F8FC !default;
$gray-110:                   #FFF9F2 !default;
$gray-150:                   #F2F6FB !default;
$gray-160:                   #F9FBFD !default;
$gray-170:                   #E9F2F0 !default;
$gray-180:                   #D9D9D9 !default;
$gray-200:                   #D4E1F2 !default;
$gray-300:                   #C4C4C4 !default;
$gray-400:                   #A1AAB7 !default;
$gray-700:                   #68768B !default;
$black:                      #000000 !default;
$teal:                       #3CEAC1 !default;
$green:                      #01926F !default;
$teal-light:                 #EBFDF9 !default;
$teal-dark:                  #01CD9D !default;
$green-dark:                 #1F342F !default;
$green-dark-2:               #175C4B !default;
$green-dark-3:               #21816A !default;
$green-dark-4:               #196251 !default;
$green-dark-5:               #2BA689 !default;
$green-dark-6:               #12473A !default;
$light-mint:                 #DEFAF6 !default;
$pink:                       #FFDDE2 !default;
$red:                        #FE4949 !default;
$red-light:                  #FF0000 !default;
$red-faded:                  #F9E0E6 !default;
$yellow:                     #FFDA79 !default;
$yellow-dark:                #F2BF00 !default;
$blue-light:                 #F1F7FF !default;
$blue-deep:                  #4680AC !default;
$blue-dark:                  #294C66 !default;
$blue-snow:                  #EFF8FE !default;
$blue-lake-ice:              #59A4DC !default;
$turquoise:                  #B5E8E0 !default;
$purple:                     #854BFF !default;

$primary:                    $teal !default;
$secondary:                  $green-dark !default;

$error:                      $red !default;
$warning:                    $yellow-dark !default;
$success:                    $teal !default;

// BASE
$spacers: (
  0:   0,
  5:   5px,
  8:   8px,
  10:  10px,
  12:  12px,
  15:  15px,
  20:  20px,
  25:  25px,
  30:  30px,
  35:  35px,
  40:  40px,
  45:  45px,
  50:  50px,
  55:  55px,
  60:  60px,
  65:  65px,
  70:  70px,
  75:  75px,
  80:  80px,
  85:  85px,
  90:  90px,
  95:  95px,
  100: 100px
) !default;

$body-bg:                    $white !default;
$body-font-size:             16px !default;
$body-line-height:           1.5 !default;
$body-letter-spacing:        0 !default;
$body-font-family:           "Public Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$body-font-weight:           400;
$body-color:                 $secondary !default;

$link-color:                 $secondary !default;
$link-decoration:            none !default;
$link-hover-color:           $primary !default;
$link-hover-decoration:      none !default;

// TYPOGRAPHY
$font-weight-regular:        400 !default;
$font-weight-medium:         500 !default;
$font-weight-semibold:       600 !default;
$font-weight-bold:           700 !default;
$font-weight-black:          900 !default;

$headings-font-family:       "Mulish", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-color:             null !default;
$headings-font-weight:       $font-weight-semibold !default;
$headings-line-height:       1.2 !default;
$font-size-xs:               0.75rem !default;    // 12px
$font-size-sm:               0.875rem !default;   // 14px
$font-size-lg:               1.125rem !default;   // 18px
$font-size-xl:               1.25rem !default;    // 20px
$font-size-xxl:              1.375rem !default;   // 22px

// BUTTONS
$btn-height:                 60px !default;
$btn-border-width:           2px !default;
$btn-border-radius:          10px !default;
$btn-font-size:              16px !default;
$btn-font-weight:            700 !default;
$btn-font-family:            $headings-font-family !default;

$btn-sm-height:              48px !default;
$btn-sm-border-radius:       10px !default;
$btn-sm-font-size:           16px !default;

// FORMS
$input-height:               $btn-height !default;
$input-border-width:         1px !default;
$input-border-color:         $gray-200 !default;
$input-background-color:     $white !default;
$input-font-size:            16px !default;
$input-line-height:          1.6 !default;
$input-color:                $secondary !default;
$input-placeholder-color:    $gray-700 !default;
$input-border-radius:        4px !default;
$input-padding-x:            16px !default;
$input-padding-y:            16px !default;
$input-padding:              $input-padding-y $input-padding-x !default;
$input-hover-border-color:   $gray-400 !default;
$input-transition:           border-color 0.3s, background-color 0.3s !default;

$input-sm-height:            42px !default;
$input-sm-padding-x:         16px !default;
$input-sm-padding-y:         8px !default;
$input-sm-padding:           $input-sm-padding-y $input-sm-padding-x !default;

// TOOLTIP
$tooltip-width:              290px !default;
$tooltip-border-width:       1px !default;
$tooltip-border-color:       $purple !default;
$tooltip-border:             $tooltip-border-width solid $tooltip-border-color !default;
$tooltip-background-color:   $white !default;
$tooltip-color:              $secondary !default;
$tooltip-border-radius:      12px !default;
$tooltip-font-size:          14px !default;
$tooltip-line-height:        1.6 !default;
$tooltip-arrow-bg:           $tooltip-border-color !default;

// BREAKPOINTS
$layout-flip-var: 900px;
$grid-breakpoints: (
  xxxs: 0,
  xxs: 425px,
  xs: 640px,
  sm: 768px,
  layout-flip: 901px,
  md: 1024px,
  lg: 1280px,
  xl: 1536px
) !default;

$container-max-widths: (
  xs: 540px,
  sm: 720px,
  layout-flip: 860px,
  md: 960px,
  lg: 1140px,
  xl: 1320px
) !default;


$grid-columns:               12 !default;
$grid-gutter-width:          40px !default;
$grid-gutter:                $grid-gutter-width * 0.5 !default;;
$grid-row-columns:           6 !default;

$container-normal-width:     1160px + $grid-gutter-width;

// Z-INDEX MASTER LIST
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1045 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1055 !default;
$zindex-popover:                    1070 !default;
$zindex-tooltip:                    1080 !default;
$zindex-toast:                      1090 !default;