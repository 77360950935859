:root {
  --rt-opacity: 1 !important;
}

body {
  .react-tooltip {
    width: $tooltip-width;
    border: $tooltip-border;
    background-color: $tooltip-background-color;
    color: $tooltip-color;
    border-radius: $tooltip-border-radius;
    font-size: $tooltip-font-size;
    line-height: $tooltip-line-height;
    pointer-events: inherit;
    z-index: $zindex-tooltip;

    &__place-top {
      box-shadow: 0px -8px 24px rgba($black, 0.16);

      .react-tooltip-arrow {
        @include borderArrow(24, 10, $tooltip-arrow-bg, b);

        bottom: auto !important;
        top: 100%;
      }
    }

    &__place-bottom {
      box-shadow: 0px 8px 24px rgba($black, 0.16);

      .react-tooltip-arrow {
        @include borderArrow(24, 10, $tooltip-arrow-bg, t);

        top: auto !important;
        bottom: 100%;
      }
    }

    &__place-left {
      box-shadow: 0px 8px 24px rgba($black, 0.16);

      .react-tooltip-arrow {
        @include borderArrow(10, 24, $tooltip-arrow-bg, r);

        right: auto !important;
        left: 100%;
      }
    }

    &__place-right {
      box-shadow: 0px 8px 24px rgba($black, 0.16);

      .react-tooltip-arrow {
        @include borderArrow(10, 24, $tooltip-arrow-bg, l);

        left: auto !important;
        right: 100%;
      }
    }

    &-arrow {
      background-color: transparent;
      transform: none;
    }
  }
}

.tooltip-icon {
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s;
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}