@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.modality {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.2;
	color: $gray-700;

	@include media-breakpoint-down(md) {
		font-size: 14px;
	}
}

.modalityIcon {
	width: 63px;
	height: 63px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 8px 0 0;
	background: $gray-100;
	border-radius: 50%;

	@include media-breakpoint-down(md) {
		width: 32px;
		height: 32px;
	}
}

.modalityText {
	&.modalityTextHidden {
		display: none;
	}
}
