@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.available {
	&--red {
		.available__badge {
			color: $red;
			background-color: $red-faded;
			border: 1px dashed $red;
		}
	}

	&__badge {
		display: inline-flex;
		align-items: center;
		padding: 5px 8px;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.2;
		letter-spacing: -0.01em;
		color: #21816A;
		border-radius: 5px;
		border: 1px dashed #21816A;
		background: #3CEAC1;

		@include media-breakpoint-up(layout-flip) {
			font-size: 16px;
			padding: 9px 8px;
		}
	}

	&__icon {
		width: 16px;
		margin-right: 8px;
		flex-shrink: 0;

		@include media-breakpoint-up(layout-flip) {
			width: 20px;
			margin-right: 10px;
		}
	}

	&__text {
		display: flex;
		font-size: 14px;
		margin-top: 15px;
	}

	&__text-icon {
		width: 20px;
		height: 20px;
		object-fit: contain;
		flex-shrink: 0;
		margin-right: 5px;
	}
}