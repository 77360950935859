@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.playerContainer {
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	@include media-breakpoint-down(layout-flip) {
		height: 403px;
	}

	video {
		max-height: 418px;
		object-fit: contain;
		border-radius: 20px;

		@include media-breakpoint-down(md) {
			min-height: 206px;
			object-fit: cover;
		}
	}
}

.fullScreen {
	height: 100%;
	width: 100%;

	video {
		object-fit: contain;
	}
}

.playIconOverlay {
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	@include restrict-prop(width, 68px);
	@include restrict-prop(height, 68px);
	opacity: 100%;
	transition: opacity 0.15s;
}

.controls {
	opacity: 100%;
	transition: opacity 0.3s;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}
