@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.react-select {
	.react-select {
		&__placeholder {
			color: $input-placeholder-color;
		}

		&__control {
			font-size: $input-font-size;
			min-height: calc($btn-height - 10px);
			border: $input-border-width solid $input-border-color;
			border-radius: $input-border-radius;
			line-height: 19px;
			color: $input-color;
			padding: 10px 6px 11px;
			cursor: pointer;
			box-shadow: none;

			@include media-breakpoint-up(md) {
				height: $input-height;
			}

			&:hover,
			&:focus {
				border-color: $input-hover-border-color;
			}
		}
	}

	&__indicator-separator {
		display: none;
	}

	.css-1pahdxg-control {
		border-color: #3ceac1;
		box-shadow: none;
	}

	&__container {
		z-index: 10;
	}
}

.small .react-select {
	&__control {
		padding: 0;
	}
}

.placeholder-as-label .react-select__placeholder {
	opacity: 100%;
}

.high-index {
	z-index: 11;
}
