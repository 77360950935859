#root {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  font-family: $body-font-family;
  font-size: $body-font-size;
  line-height: $body-line-height;
  letter-spacing: $body-letter-spacing;
  font-weight: $body-font-weight;
  color: $body-color;
  background-color: $body-bg;
  min-width: 320px;
}

.container-normal {
  max-width: $container-normal-width;
  width: 100%;
  margin: 0 auto;
  padding: 0 $grid-gutter;
}

.container-listing-normal {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 $grid-gutter;
}