@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.therapist-left-side {
  &__avatar {
    position: relative;
    margin: 0 0 32px;
  }

  &__img {
    display: block !important;
    max-width: 100% !important;
    width: 100% !important;
    margin-bottom: 32px !important;

    a {
      pointer-events: none;
    }
  }

  &__button {
    margin-bottom: 15px;
  }

  &__lead {
    margin: 0 0 24px;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    color: $green-dark;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 42px;
    margin: 0 0 16px;
    padding: 11px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: $gray-700;
    background: $gray-100;
    border: 1px solid $gray-200;
    border-radius: 40px;

    &:nth-child(odd) {
      margin: 0 8px 16px 0;
    }
  }
}
