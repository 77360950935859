@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.funnelPage {
  max-width: 868px;
  width: 100%;
  margin: auto;
  padding: 96px 48px;

  @include media-breakpoint-down(sm) {
    margin: 0;
    padding: 10px 24px;
  }
}
