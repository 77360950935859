@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?gw2i4e') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?gw2i4e') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?gw2i4e##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-chat {
  &:before {
    content: $icomoon-chat; 
  }
}
.icomoon-phone {
  &:before {
    content: $icomoon-phone; 
  }
}
.icomoon-video {
  &:before {
    content: $icomoon-video; 
  }
}
.icomoon-round-6-years {
  &:before {
    content: $icomoon-round-6-years; 
  }
}
.icomoon-round-thumbs-up {
  &:before {
    content: $icomoon-round-thumbs-up; 
  }
}
.icomoon-round-user {
  &:before {
    content: $icomoon-round-user; 
  }
}
.icomoon-round-evaluation {
  &:before {
    content: $icomoon-round-evaluation; 
  }
}
.icomoon-round-video {
  &:before {
    content: $icomoon-round-video; 
  }
}
.icomoon-round-phone {
  &:before {
    content: $icomoon-round-phone; 
  }
}
.icomoon-round-message {
  &:before {
    content: $icomoon-round-message; 
  }
}
.icomoon-info-light {
  &:before {
    content: $icomoon-info-light; 
  }
}
.icomoon-remove {
  &:before {
    content: $icomoon-remove; 
  }
}
.icomoon-checkmark {
  &:before {
    content: $icomoon-checkmark; 
  }
}
.icomoon-info {
  &:before {
    content: $icomoon-info; 
  }
}

