@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.simpleHeader {
	padding: 30px 0;

	&__logo {
		display: flex;
		width: 200px;

		@include media-breakpoint-up(sm) {
			width: 220px;
		}

		@include media-breakpoint-up(lg) {
			width: 320px;
		}

		> img {
			width: 100%;
			height: auto;
		}
	}
}