.spinner {
	width: 70px;
	height: 70px;
	margin: 24px;
	animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
	100% {
		transform: rotate(360deg);
	}
}
