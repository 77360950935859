// FONT-WEIGHT HELPER ================================================

// 100 Extra Light or Ultra Light;
// 200 Light or Thin;
// 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black;

// FONTS ================================================
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;900&family=Public+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");