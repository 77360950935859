@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.bottomPartWrapper {
	position: absolute;
	left: 24px;
	right: 40px;
	bottom: 32px;
	display: flex;
	align-items: center;
	column-gap: 32px;
	z-index: 5;

	@include media-breakpoint-down(layout-flip) {
		column-gap: 16px;
		right: 16px;
		left: 16px;
	}
}

.controlsContainer {
	padding: 8px 24px 8px 8px;
	display: flex;
	flex: 1;
	align-items: center;
	border-radius: 30px;
	background: white;
	column-gap: 16px;
}

.passedTime {
	background: #3ceac1;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	border-radius: 10px;
}

.progressBar {
	border-radius: 10px;
	position: relative;
	height: 3px;
	background: #d4e1f2;
	flex: 1;
	cursor: pointer;

	&:hover {
		height: 5px;

		.thumb {
			width: 18px;
		}
	}
}

.thumb {
	position: absolute;
	transform: translateX(-50%);
	background: #3ceac1;
	border: 3px solid white;
	width: 16px;
	top: -6.5px;
	bottom: -6.5px;
	border-radius: 50%;
	box-shadow: 0 5px 10px rgba(65, 170, 158, 0.35);
	cursor: pointer;
}

.playButton {
	border: 1px solid #f4f8fc;
	border-radius: 50%;
	@include restrict-prop(height, 42px);
	@include restrict-prop(width, 42px);
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none !important;
	padding: 0;
	cursor: pointer;

	&:hover {
		background: rgba(244, 248, 252, 0.91);
	}
}
