@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: $btn-font-family;
	border: $btn-border-width solid transparent;
	outline: none;
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s, color 0.3s, border-color 0.3s;

	// size
	&:not(.btn-sm):not(.btn-lg) {
		min-height: calc($btn-height - 10px);
		padding: 5px 25px;
		font-size: $btn-font-size;
		font-weight: $btn-font-weight;
		border-radius: $btn-border-radius;
		line-height: 1.125;
		letter-spacing: 0.01em;
		min-width: 155px;

		@include media-breakpoint-up(md) {
			min-height: $btn-height;
			padding: 10px 32px;
		}

		svg {
			@include sizeElement(24px);

			object-fit: contain;
			margin-right: 10px;
			flex-shrink: 0;
		}
	}

	&-sm {
		font-size: 14px;
		font-weight: $btn-font-weight;
		min-height: $btn-sm-height;
		padding: 8px 20px;
		border-radius: $btn-sm-border-radius;
		border-width: 1px;
		min-width: 108px;

		@include media-breakpoint-up(xxs) {
			font-size: $btn-sm-font-size;
		}

		svg {
			@include sizeElement(20px);

			object-fit: contain;
			margin-right: 10px;
			flex-shrink: 0;
		}
	}

	// color
	&-primary {
		background-color: $primary;
		border-color: $primary;
		color: $secondary;

		&:hover {
			background-color: darken($primary, 10%);
			border-color: darken($primary, 10%);
			color: $secondary;
		}
	}

	&-outline-primary {
		background-color: transparent;
		border-color: $primary;
		color: $secondary;

		&:hover {
			background-color: rgba($primary, 0.1);
			border-color: $primary;
			color: $secondary;
		}
	}

	&-green-light {
		background-color: #A5F5E2;
		border-color: #A5F5E2;
		color: $secondary;

		&:hover {
			background-color: darken($light-mint, 5%);
			border-color: darken($light-mint, 5%);
			color: $secondary;
		}
	}

	&-outline-green-light {
		background-color: $white;
		border-color: $light-mint;
		color: $teal-dark;

		&:hover {
			background-color: rgba($light-mint, 0.2);
			border-color: $light-mint;
			color: $teal-dark;
		}
	}

	&-outline-gray {
		background-color: $white;
		border-color: $gray-200;
		color: $gray-700;

		&:hover {
			background-color: rgba($gray-200, 0.2);
			border-color: darken($gray-200, 10%);
			color: $secondary;
		}
	}

	&-red {
		background-color: $red;
		border-color: $red;
		color: $white;

		&:hover {
			background-color: darken($red, 10%);
			border-color: darken($red, 10%);
			color: $white;
		}
	}

	&-outline-red-light {
		background-color: $white;
		border-color: $pink;
		color: $red;

		&:hover {
			background-color: $white;
			border-color: darken($pink, 12.5%);
			color: $red;
		}
	}

	&-yellow {
		background-color: $yellow;
		border-color: $yellow;
		color: $secondary;

		&:hover {
			background-color: darken($yellow, 10%);
			border-color: darken($yellow, 10%);
			color: $secondary;
		}
	}

	&-turquoise {
		background-color: $turquoise;
		border-color: $turquoise;
		color: $secondary;

		&:hover {
			background-color: darken($turquoise, 10%);
			border-color: darken($turquoise, 10%);
			color: $secondary;
		}
	}

	&-gray {
		background-color: $gray-200;
		border-color: $gray-200;
		color: $secondary;

		&:hover {
			background-color: darken($gray-200, 5%);
			border-color: darken($gray-200, 5%);
			color: $secondary;
		}

		&.btn-square {
			box-shadow: 0px 10px 20px rgba($gray-200, 0.1);
		}
	}

	&-link-gray {
		background-color: transparent;
		border-color: transparent;
		color: $gray-400;

		&:hover {
			color: $secondary;
		}
	}

	&-link {
		background-color: transparent;
		border-color: transparent;
		color: $primary;

		&:hover {
			color: darken($primary, 10%);
		}
	}

	&.disabled {
		cursor: default;
		pointer-events: none;
		opacity: 0.8;
	}

	// additional
	.loader {
		@include flexCenter;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background-color: rgba($white, 0.3);
	}
}

.spinner {
	background: rgba($white, 0.5);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&:after {
		@include sizeElement(25px);

		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		border: 4px solid transparent;
		border-top-color: $white;
		border-radius: 50%;
		animation: button-loading-spinner 1s ease infinite;
	}
}

@keyframes button-loading-spinner {
	from {
		transform: rotate(0turn);
	}

	to {
		transform: rotate(1turn);
	}
}
