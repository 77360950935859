@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.stack {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    gap: 15px;
    max-width: none;
  }
}

.horizontalWithLabel, .horizontalWithoutLabel {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    gap: 15px;
  }
}
