@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.input + label {
	display: inline-flex;
	cursor: pointer;
	user-select: none;

	color: #68768b;

	&.error {
		&::before {
			border-color: $red;
		}
	}
}

.label {
	&.xs {
		line-height: 1.6;
		font-size: 12px;

		&:before {
			@include sizeElement(20px);
		}
	}

	&.sm {
		line-height: 16px;
		font-size: 14px;
	}

	&.md {
		line-height: 18px;
		font-size: 16px;
	}

	.more {
		font-weight: 600;
		color: $secondary;
		margin-left: 4px;
		border-bottom: 1px solid currentColor;
		transition: border-color 0.3s;

		&:hover {
			border-color: $primary;
		}
	}
}

.input + label a {
	font-size: inherit;
	line-height: inherit;
}

.input + label::before {
	@include sizeElement(25px);

	content: "";
	display: inline-block;
	flex-shrink: 0;
	flex-grow: 0;
	background: $white;
	border: 1.5px solid $gray-200;
	border-radius: 4px;
	margin-right: 10px;
	background-size: 50% 50%;
	cursor: pointer;
	vertical-align: middle;
}

.input:focus:not(:checked) + label:not(.error)::before {
	border-color: $gray-200;
}

.input:checked + label::before {
	border-color: $primary;
	background-color: $primary;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("/assets/icons/check.svg");
	background-size: 12px;
}

.input:disabled + label::before,
.input:disabled + label {
	opacity: 0.8;
	cursor: default;
}

.checkbox-size-md {
	font-size: 16px;
}
