@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.section {
	padding: 20px 0;

	@include media-breakpoint-up(xxs) {
		padding: 30px 0;
	}

	@include media-breakpoint-up(layout-flip) {
		padding: 55px 0;
	}
}

.videoIframeHolder {
  @include responsiveRatio(16, 9);

  position: relative;
  background-color: $secondary;
  border: 1px solid $secondary;
  border-radius: 4px;
  overflow: hidden;
}

.cardInfo {
	border-radius: 10px;
	background-color: $white;
	box-shadow: 1px 3px 12px 0px rgba($black, 0.25);
	padding: 24px 16px;
	width: 100%;
	text-align: center;
	margin-bottom: 15px;

	@include media-breakpoint-up(xxs) {
		margin-bottom: 24px;
	}

	@include media-breakpoint-up(sm) {
		margin-bottom: 36px;
	}

	&.horizontalOnMobile {
		@include media-breakpoint-down(xxs) {
			position: relative;
			padding: 20px 20px 20px 75px;
			text-align: left;
		}

		.cardInfo__icon {
			@include media-breakpoint-down(xxs) {
				@include sizeElement(40px);

				position: absolute;
				top: 20px;
				left: 16px;
			}
		}

		.cardInfo__iconHolder {
			@include media-breakpoint-down(xxs) {
				@include sizeElement(20px);
			}
		}
	}

	&.blueBg {
		color: $white;
		background-color: $blue-lake-ice;

		.cardInfo__icon {
			border-color: $white;
		}
	}

	&__icon {
		@include sizeElement(64px);
		@include inlineFlexCenter;

		border-radius: 50%;
		border: 1px solid $blue-deep;
		margin-bottom: 16px;
	}

	&__iconHolder {
		@include sizeElement(32px);

		position: relative;
	}
}

.cardProcess {
	text-align: center;
	margin-bottom: 20px;

	@include media-breakpoint-up(sm) {
		margin-bottom: 40px;
	}

	&__number {
		@include sizeElement(50px);
		@include inlineFlexCenter;

		margin-bottom: 10px;
		border-radius: 50%;
		background-color: $blue-deep;
		font-family: $headings-font-family;
		color: $white;
		font-size: 24px;
		line-height: 1;
		font-weight: 700;

		@include media-breakpoint-up(md) {
			@include sizeElement(68px);

			font-size: 36px;
		}
	}
}

.sectionWelcome {
	padding-top: 0;

	&__imageBlock {
		position: relative;
		height: 460px;

		@include media-breakpoint-up(lg) {
			height: 580px;
		}
	}

	&__imageWrap {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		@include media-breakpoint-up(layout-flip) {
			top: -100px;
		}
	}

	&__logoBlock {
		position: relative;
		z-index: 5;
		padding: 20px 0;

		@include media-breakpoint-up(sm) {
			padding: 35px 0;
		}
	}

	&__logo {
		display: inline-flex;
		max-width: 200px;
		width: 100%;

		@include media-breakpoint-up(lg) {
			max-width: 260px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 320px;
		}

		img {
			width: 100%;
		}
	}

	&__bannerWrap {
		position: relative;
		z-index: 10;
		margin-top: -150px;

		@include media-breakpoint-up(layout-flip) {
			margin-top: -230px;
		}
	}

	&__banner {
		background-color: $white;
		border-radius: 20px;
		box-shadow: 0px 3px 12px 0px rgba($black, 0.25);
		max-width: 1036px;
		margin: 0 auto;
		padding: 20px;
		text-align: center;

		@include media-breakpoint-up(layout-flip) {
			border-radius: 30px;
			padding: 40px;
		}

		@include media-breakpoint-up(lg) {
			padding: 40px 110px;
		}
	}

	&__stars {
		display: inline-block;
		max-width: 215px;
		width: 100%;
		margin-bottom: 10px;
	}
}

.sectionInfo {
	&__mainContent {
		max-width: 1036px;
		margin: 0 auto;
	}

	&__cardsContent {
		max-width: 921px;
		margin: 0 auto;
	}
}

.sectionProcess {
	&__imgBlock {
		position: relative;
		height: 380px;
		border-radius: 20px;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			height: 500px;
		}
	}

	&__contentBlockWrap {
		padding: 0 10px;

		@include media-breakpoint-up(md) {
			padding: 0 50px;
		}
	}

	&__contentBlock {
		position: relative;
		z-index: 5;
		background-color: $white;
		border-radius: 30px;
		box-shadow: 0px 3px 12px 0px rgba($black, 0.25);
		padding: 20px 10px;
		max-width: 1080px;
		margin: -120px auto 0;

		@include media-breakpoint-up(sm) {
			padding: 40px 20px;
		}
	}
}

.sectionTrusted {
	background: linear-gradient($gray-110, $blue-snow);

	&__bannerWrap {
		position: relative;
		max-width: 1040px;
		margin: 0 auto 30px;

		@include media-breakpoint-up(layout-flip) {
			margin-bottom: 70px;
		}
	}

	&__banner {
		position: relative;
		overflow: hidden;
		height: 350px;
		border-radius: 30px;
		margin-bottom: 20px;

		@include media-breakpoint-up(layout-flip) {
			height: 580px;
			margin-bottom: 0;
		}

		img {
			@include media-breakpoint-down(layout-flip) {
				object-position: right;
			}
		}
	}

	&__bannerContent {
		@include media-breakpoint-up(layout-flip) {
			position: absolute;
			top: 50%;
			left: 40px;
			transform: translate(0, -50%);
			z-index: 5;
			width: 400px;
		}

		@include media-breakpoint-up(md) {
			left: 70px;
		}

		@include media-breakpoint-up(lg) {
			left: 110px;
		}
	}

	&__stars {
		display: block;
		max-width: 215px;
		margin: 0 auto 10px;

		@include media-breakpoint-up(layout-flip) {
			margin-bottom: 15px;
		}
	}

	&__quote {
		font-size: 18px;
		line-height: 1.2;
		font-weight: 500;
		font-family: $headings-font-family;
		text-align: center;

		@include media-breakpoint-up(layout-flip) {
			text-align: left;
			color: $white;
			font-size: 22px;
		}

		footer {
			padding-top: 20px;

			@include media-breakpoint-up(layout-flip) {
				padding-top: 30px;
			}
		}
	}
}

.sectionFaq {
	background-color: $blue-snow;

	&__faqList {
		max-width: 1023px;
		margin: 0 (-$grid-gutter);

		@include media-breakpoint-up(xs) {
			margin: 0 auto;
		}
	}

	&__faqListItem {
		border-bottom: 1px solid $blue-dark;
		padding: 0 $grid-gutter;

		&:first-child {
			border-top: 1px solid $blue-dark;
		}

		@include media-breakpoint-up(xs) {
			padding: 0;
		}

		&.active {
			.sectionFaq__faqHeader {
				&:after {
					content: "-";
				}
			}
		}
	}

	&__faqHeader {
		position: relative;
		display: block;
		width: 100%;
		text-align: left;
		background-color: transparent;
		border: none;
		padding: 15px 50px 15px 0;
		transition: color 0.3s;

		&:hover {
			color: $blue-deep;
		}

		&:after {
			content: "+";
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translate(0, -50%);
			z-index: 5;
			display: inline-block;
			font-size: 32px;
			font-family: $headings-font-family;
			font-weight: 700;
			margin-top: -3px;
		}
	}

	&__faqBody {
		padding-bottom: 20px;
	}
}