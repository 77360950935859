@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.loadingContainer {
	position: relative;
	min-height: 100px;

	.overlay {
		z-index: 10;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: #ffffffc0;

		.loadingMessage {
			position: absolute;
			left: 50%;
			top: 64px;
			transform: translate(-50%, -50%);
			display: flex;
			align-items: center;

			@media (max-width: 1000px) {
				flex-direction: column;
			}

			.spinner {
				margin: 16px;

				@include media-breakpoint-down(layout-flip) {
					width: 50px;
					height: 50px;
				}
			}

			p {
				margin: 0;
				font-size: 40px;
				white-space: nowrap;

				@media (max-width: 1000px) {
					font-size: 30px;
				}

				@media (max-width: 700px) {
					font-size: 16px;
					white-space: nowrap;
				}
			}
		}
	}
}
