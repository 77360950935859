@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.preloader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: $headings-font-family;
	font-weight: 400;
	font-size: 50px;
	line-height: 63px;
	color: $green-dark;
	overflow: hidden;
	background: #fff;
	z-index: 1001;

	@include media-breakpoint-down(layout-flip) {
		flex-direction: column;
		font-size: 23px;
		line-height: 34px;
	}
}

.loaded_hiding .preloader {
	transition: 0.3s opacity;
	opacity: 0;
}

.loaded .preloader {
	display: none;
}
