@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.therapistCard {
	display: flex;
	max-width: 806px;
	width: 100%;
	background: $white;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	border: 1px solid $gray-200;
	overflow: hidden;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		border-radius: 15px;
	}

	.imageContainer {
		flex-shrink: 0;
		position: relative;
		width: 300px;
		overflow: hidden;
		margin-bottom: 0px;

		@include media-breakpoint-down(sm) {
			width: auto;
			height: 400px;
		}

		@include media-breakpoint-down(sm) {
			height: 250px;
		}

		.avatar {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.info {
		width: 100%;
		padding: 56px 40px;

		@include media-breakpoint-down(md) {
			padding: 45px 40px;
		}

		@include media-breakpoint-down(sm) {
			padding: 30px;
		}

		.name {
			font-weight: 600;
			font-size: 30px;
			line-height: 38px;

			@include media-breakpoint-down(md) {
				margin: 0 0 19px;
				font-size: 35px;
				line-height: 44px;
			}

			@include media-breakpoint-down(sm) {
				margin: 0 0 10px;
				font-size: 22px;
				line-height: 28px;
			}
		}

		.role {
			display: flex;
			align-items: center;
			margin: 0 0 1rem;
			font-family: $headings-font-family;
			font-weight: 400;
			font-size: 25px;
			line-height: 31px;

			@include media-breakpoint-down(md) {
				margin: 0 0 40px;
				font-size: 20px;
				line-height: 24px;
				color: $gray-700;
			}

			@include media-breakpoint-down(sm) {
				margin: 0 0 10px;
				font-size: 14px;
				line-height: 20px;
			}

			.verifiedBadge {
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: $gray-700;
				border-radius: 5px;
				border: 1px dashed #21816A;
				background: #3CEAC1;
				padding: 0 5px;

				@include media-breakpoint-down(md) {
					margin: 0 0 0 0px;
				}

				@include media-breakpoint-down(sm) {
					margin: 0 0 0 00px;
				}

				.iconContainer {
					flex-shrink: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 25px;
					height: 25px;
					margin: 0 8px 0 0;
					background: $primary;
				}
			}
		}

		.license {
			display: flex;
			align-items: center;
			margin: 0 0 50px;
			font-weight: 400;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.01em;
			color: $gray-700;

			@include media-breakpoint-down(md) {
				margin: 0 0 40px;
			}

			@include media-breakpoint-down(sm) {
				margin: 0 0 10px;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: -0.01em;
			}
		}

		.mapMarker {
			margin: 0 15px 0 0;

			@include media-breakpoint-down(sm) {
				margin: 0 8px 0 0;
			}
		}

		.therapyOptions {
			display: flex;
			align-items: center;
			font-size: 18px;
			line-height: 21px;
			letter-spacing: 0.01em;
			color: #162521;

			@include media-breakpoint-down(md) {
				flex-direction: column;
				align-items: flex-start;
				font-size: 20px;
				line-height: 24px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 14px;
				line-height: 16px;
				letter-spacing: -0.01em;
			}

			ul {
				display: flex;
				align-items: center;
				gap: 16px;
				margin: 5px 0 0 0px;

				@include media-breakpoint-down(md) {
					margin: 10px 0 0;
				}

				@include media-breakpoint-down(sm) {
					gap: 16px;
				}
			}
		}
	}
}
