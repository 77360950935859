@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.therapist-card {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	+ .therapist-card {
		margin-top: 50px;
		border-top: 1px solid $gray-200;
	}

	&__photo-col {
		@include media-breakpoint-down(md) {
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 !important;
		}

		@include media-breakpoint-down(xs) {
			width: 60px !important;
		}

		@include media-breakpoint-up(md) {
			width: 175px !important;
		}

		@include media-breakpoint-up(lg) {
			width: 205px !important;
		}
	}

	&__btn-col {

		@include media-breakpoint-up(layout-flip) {
			width: 235px !important;
		}

		@include media-breakpoint-up(md) {
			width: 350px !important;
		}
	}


	&__name {
		font-size: 20px;
		line-height: 1.25;
		padding-right: 18px;

		@include media-breakpoint-up(xs) {
			display: flex;
			flex-wrap: wrap;
			font-size: 24px;
			padding-right: 0;
		}
	}

	&__verified-badge {
		@include media-breakpoint-down(xs) {
			margin-left: -18px !important;
			transform: translate(18px, 1px);
		}

		@include media-breakpoint-up(xs) {
			margin-top: 5px;
		}
	}

	&__info-content {
		padding-left: 75px;

		@include media-breakpoint-up(xs) {
			padding-left: 140px;
		}

		@include media-breakpoint-up(md) {
			padding-left: 0;
		}
	}
}
