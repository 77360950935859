@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.choiceCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	max-width: 222px;
	width: 100%;
	height: 243px;
	padding: 24px 54px;
	font-size: 20px;
	line-height: 28px;
	border: 1px solid var(--foundation-dark-green-dark-green-600, #1C2F2B);
	border-radius: 6px;
	cursor: pointer;
	
	svg {
		color: #1C2F2B;
	}
.catDogIcon
{
	scale: 2;
}

.catDogIconText {
	padding-top: 10px;
}

	&.checked {
		border-radius: 6px;
		border: 1px solid #3CEAC1;
		background: #DEFAF6;
		color: #01BB8F;

		svg {
			color: #01BB8F;
		}
	}

	input {
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
		appearance: none;
		margin: 0;
	}
}
