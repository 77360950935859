@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.modalityList {
	display: flex;
	flex-wrap: wrap;
	margin: -20px -16px 0;
	padding: 0;
	list-style: none;

	@include media-breakpoint-down(layout-flip) {
		margin: 0 -14px;
		overflow: auto;
	}
}

.modalityListItem {
	margin: 20px 16px 0;

	@include media-breakpoint-down(layout-flip) {
		margin: 20px 14px 0;
	}
}
