.returning {
  background: #edf3fd;
  min-height: 100vh;

  .top-right-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;

    @include media-breakpoint-up(sm) {
      width: 738px;
      height: 738px;
    }
  }

  .bottom-left-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 320px;
    height: 100px;

    @include media-breakpoint-up(sm) {
      width: 671px;
      height: 320px;
    }
  }

  .custom-container {
    max-width: 1440px;
    margin: 0 auto;
  }

  .main-content {
    position: relative;
    height: 1342px;

    @include media-breakpoint-up(sm) {
      height: 1450px;
    }

    @media (min-width: 1600px) {
      height: calc(100vh - 160px);
    }
  }

  .content {
    padding: 16px 20px;

    @include media-breakpoint-up(sm) {
      padding: 20px 40px;
    }

    .logo {
      width: 180px;

      @include media-breakpoint-up(sm) {
        width: 240px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .wrap {
      margin-top: 41px;

      @include media-breakpoint-up(md) {
        margin-top: 80px;
        margin-bottom: 40px;
      }

      .left-wrap {
        @include media-breakpoint-up(md) {
          width: 631px;
        }
      }

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 120%;
        color: #294c66;
        text-align: center;

        @include media-breakpoint-up(sm) {
          text-align: left;
          font-size: 56px;
        }
      }

      .desc {
        color: #294c66;
        font-size: 18px;
        margin-top: 20px;
        text-align: left;

        @include media-breakpoint-up(sm) {
          font-size: 22px;
        }
      }

      .communication {
        padding: 24px 0;

        @include media-breakpoint-up(sm) {
          padding: 40px 0;
        }

        .item {
          @include media-breakpoint-up(sm) {
            margin-right: 24px;

            &:last-of-type {
              margin-right: 0;
            }
          }

          .text {
            color: #294c66;

            @include media-breakpoint-up(sm) {
              font-size: 24px;
              font-weight: 600;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .my-40 {
    margin: 40px 0;
  }

  .search-wrap {
    margin-bottom: 40px;

    @include media-breakpoint-up(sm) {
      display: flex;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      max-width: 560px;
    }

    .input {
      margin-bottom: 8px;

      .search {
        background-color: transparent;
        border-radius: 6px;
        border: 1px solid #8e98a8;
      }

      @include media-breakpoint-up(sm) {
        flex: 1;
        margin-right: 15px;
        margin-bottom: 0;
      }
    }

    .btn-connect {
      width: 100%;
      border-radius: 8px;
      background: #62b4f2;
      color: #fff;
      border: none;

      @include media-breakpoint-up(sm) {
        flex: 1;
        padding: 0 !important;
      }
    }
  }

  .footer {
    background: #4680ac;
    color: #fff;
    padding: 40px;

    @include media-breakpoint-up(sm) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include media-breakpoint-up(md) {
      padding-top: 40px;
      padding-bottom: 40px;
      height: 160px;
    }

    .item-space-between {
      margin-top: 40px;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(sm) {
        margin-top: 32px;

        &:nth-of-type(2) {
          margin-top: 0;
        }
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    .title {
      font-size: 40px;
      font-weight: 700;
      text-align: center;

      @include media-breakpoint-up(md) {
        font-size: 48px;
        line-height: 120%;
        text-align: left;
      }
    }

    .desc {
      font-size: 22px;
      line-height: 120%;
    }
  }
}
