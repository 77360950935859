@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
  .phone {
    display: inline-flex;
    align-items: center;
    margin: 0 0 16px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 23px;
    line-height: 1.23;
    color: $green-dark;

    &:last-of-type {
      margin: 0 0 40px;
    }

    .phoneIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      margin: 0 16px 0 0;
      border-radius: 50%;
      border: 1px solid $gray-200;
    }

    .phoneText {
      display: flex;
      white-space: nowrap;
      flex-direction: column;
    }

    small {
      margin: 5px 0 0;
      font-size: 20px;
      color: #1f342fa6;
      letter-spacing: -1px;
    }

    &.disabled {
      pointer-events: none;

      .phoneIcon {
        filter: grayscale(1);
      }
    }

    @media (max-width: 1366px) {
      font-size: 20px;

      .phoneIcon {
        width: 30px;
        height: 30px;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      small {
        margin: 0 0 0 4px;
        font-size: 18px;
      }
    }
  }

  .button {
    margin-bottom: 15px;
  }
}
