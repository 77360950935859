.of-contain {
  @include absoluteCover;

  object-fit: contain;
}

.of-cover {
  @include absoluteCover;

  object-fit: cover;
}

.no-scroll {
  overflow: hidden !important;
}

.row {
  @include addGridGutter(10);
  @include addGridGutter(12);
  @include addGridGutter(16);
  @include addGridGutter(20);
  @include addGridGutter(24);
}

.width-full {
  width: 100% !important;
}

.min-height-100-vh {
  min-height: 100vh !important;
}

.max-width-360 {
  max-width: 360px !important;
}

.w-xxs-auto {
  @include media-breakpoint-up(xxs) {
    width: auto !important;
  }
}