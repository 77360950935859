@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.notFoundPage {
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 96px 48px;

  @include media-breakpoint-down(sm) {
    gap: 50px;
    padding: 48px 24px;
  }

  svg {
    width: 88px;
    height: 88px;
  }

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
  }

  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $teal-dark;
    text-decoration-line: underline;
  }
}
