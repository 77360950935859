@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.sectionCheckoutCart {
  min-height: 100vh;
  padding: 40px 0;
  background: linear-gradient(180deg, #E5FBF8 0%, #ECFDF9 100%);

  @include media-breakpoint-up(xxs) {
    padding: 60px 0;
  }

  &__contentBlock {
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 6px;

    .sectionCheckoutCart__contentHeading {
      &:first-child {
        border-radius: 6px 6px 0 0;
      }

      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }
  }

  &__contentHeading {
    padding: 10px 15px;
    background-color: $blue-light;
  }

  &__contentTitle {
    font-size: 20px;
  }

  &__contentBody {
    padding: 25px 15px;
  }

  &__cart {
    font-size: 15px;
    letter-spacing: -0.01em;

    @include media-breakpoint-up(xxs) {
      font-size: 17px;
    }
  }

  &__cartItem {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__cartItemSubtotal {
    border-top: 1px solid $gray-200;
    padding-top: 20px;
    margin-top: 40px;
  }

  &__cartRemoveBtn {
    font-size: 12px;
    letter-spacing: 0.01em;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid currentColor;
    border-radius: 0;
    padding: 0;
    color: $error;
    text-transform: uppercase;
    cursor: pointer;
    transition: border-color 0.3s;

    @include media-breakpoint-up(xxs) {
      font-size: 14px;
    }

    &:hover {
      border-color: transparent;
    }
  }
}

.title {
  margin-bottom: 10px;
  text-align: center;
}

.error_text {
  margin-bottom: 40px;
  text-align: center;
}

.xcircle {
  margin-bottom: 50px;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;

  color: #26d5b6;
  cursor: pointer;
}

.title1 {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    margin-bottom: 50px;
    font-size: 34px;
  }
}
