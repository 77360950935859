@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.text {
	display: block;
	white-space: normal;
	word-wrap: normal;

	&.breakWord {
		word-wrap: break-word;
	}

	.expandButton {
		color: $secondary;
		font-weight: 600;
		border-bottom: 1px solid currentColor;
		cursor: pointer;
		margin-left: 3px;
		transition: border-color 0.3s;

		&:hover {
			border-color: $primary;
		}
	}
}
