@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  min-height: 100vh;
  padding: 10px;
  background: linear-gradient(162deg, #E5FBF8 0%, #ECFDF9 100%);
}

.title {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    margin-bottom: 50px;
    font-size: 34px;
  }
}

.subtitle {
  max-width: 835px;
  margin: 0 0 72px;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: $gray-700;

  @include media-breakpoint-down(sm) {
    margin: 0 0 50px;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 387px;
  width: 100%;

  button {
    width: 386px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px 16px;
  width: 100%;
  max-width: 90vw;
  margin: 0 0 50px;

  .row {
    display: flex;
    gap: 24px 16px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &.--no-split {
      @include media-breakpoint-down(sm) {
        flex-direction: row;
      }
    }
  }

  & > div > * {
    flex-grow: 1;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 60px;

  button {
    width: 386px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.empty {
  margin: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsRow {
  display: flex;
  max-width: 772px;
  width: 100%;
  margin-top: 50px;

  button {
    flex-grow: 1;
  }

  button + button {
    margin-left: 32px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin-top: 25px;

    button + button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.card {
  padding: 24px 32px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  border: 2px solid #3ceac1 !important;
  border-radius: 20px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.reverseMobile {
  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
}
