@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.errorPane {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    width: 100%;
    margin: 0 0 51px;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;

    @include media-breakpoint-down(sm) {
      margin: 0 0 30px;
      text-align: left;
      font-size: 33px;
      line-height: 40px;
      letter-spacing: 0.25px;
    }
  }

  .subtitle {
    max-width: 835px;
    margin: 0 0 67px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $gray-700;

    @include media-breakpoint-down(sm) {
      margin: 0 0 40px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }
  }

  div {
    margin-bottom: 30px;
    text-align: center;
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
}
