.verified-badge {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: $body-font-family;
  font-size: 0;
  color: $gray-700;

  @include media-breakpoint-up(xs) {
    font-size: 16px;
    padding-left: 30px;
  }

  &:before {
    @include sizeElement(18px);

    content: '';
    display: block;
    border-radius: 50%;
    background-color: $primary;
    background-image: url("data:image/svg+xml;utf8,<svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.21539 8.93322L13.7076 0.5L15 1.78339L5.21539 11.5L0 6.32085L1.29237 5.03746L5.21539 8.93322Z' fill='white'/></svg>");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;

    @include media-breakpoint-up(xs) {
      @include sizeElement(25px);

      position: absolute;
      top: 0;
      left: 0;
    }
  }
}