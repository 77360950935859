@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @include media-breakpoint-down(sm) {
    gap: 60px;
  }

  &.stack {
    gap: 50px;

    @include media-breakpoint-down(sm) {
      gap: 40px;
    }
  }

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;

    @include media-breakpoint-down(sm) {
      text-align: left;
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0.25px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 10px;
    }

    button,
    a {
      max-width: 160px;

      @include media-breakpoint-down(sm) {
        max-width: 320px;
      }
    }
  }
}

.reverseMobile {
  @media (max-width: 425px) {
    flex-direction: column-reverse !important;
  }
}
