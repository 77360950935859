.therapist-options {
  display: flex;
  align-items: center;
  padding-left: 0;

  &__item-btn {
    @include sizeElement(50px);

    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    background-color: transparent;
    border: none;
    color: #21816A;
    border-radius: 50%;
    flex-shrink: 0;
    margin: 5px;
    padding: 5px;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      @include sizeElement(40px);

      margin: 8px;
      background-size: 30px;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &.person-icon {
      background-image: url("/assets/icons/offline.svg");
    background-size: 100%;
    padding: 0px;
    }

    &.phone-icon {
      background-image: url("/assets/icons/phone-gray.svg");
    padding: 0px;
    background-size: 100%;
    }

    &.video-icon {
      background-image: url("/assets/icons/video-gray.svg");
    padding: 0px;
    background-size: 100%;
    }

    &.chat-icon {
      background-image: url("/assets/icons/text.svg");
    padding: 0px;
    background-size: 100%;
    }

    // &:hover {
    //   &:before {
    //     content: attr(aria-label);
    //     position: absolute;
    //     padding: 8px;
    //     top: -53px;
    //     transform: translateX(calc(-50%));
    //     left: 50%;
    //     right: 50%;
    //     display: table;
    //     font-size: 16px;
    //     line-height: 19px;
    //     color: #1f342f;
    //     white-space: nowrap;
    //     background-color: $primary;
    //     border-radius: 5px;

    //     @include media-breakpoint-down(md) {
    //       top: -32px;
    //       font-size: 13px;
    //       line-height: 15px;
    //     }
    //   }

    //   &:after {
    //     content: "";
    //     position: absolute;
    //     top: -22px;
    //     transform: translateX(calc(-50%)) rotate(45deg);
    //     left: 50%;
    //     right: 50%;
    //     display: table;
    //     width: 8px;
    //     height: 8px;
    //     background-color: $primary;

    //     @include media-breakpoint-down(md) {
    //       top: -5px;
    //     }
    //   }
    // }
  }
}