@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.popup {
  position: relative;
  max-width: 826px;
  width: 100%;
  padding: 80px 54px;
  background: $white;
  box-shadow: 0 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;

  @include media-breakpoint-down(layout-flip) {
    display: block;
    border-radius: 30px 30px 0 0;
    height: auto;
    width: 100%;
    padding: 50px 15px 30px;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 54px;
  background: none;
  border: none;
  cursor: pointer;

  @include media-breakpoint-down(layout-flip) {
    display: none;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
  color: $green-dark;
}

.popupSubtitle {
  display: block;
  margin: 0 0 16px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $green-dark;
}

.popupButton {
  width: 100%;

  @include media-breakpoint-up(layout-flip) {
    width: 165px;
  }
}
