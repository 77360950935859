@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.homePage {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 47px;
  width: 100%;
  margin: auto;
  padding: 0 90px 70px 0;

  @include media-breakpoint-down(md) {
    margin: 0;
    padding: 95px 50px;
  }

  @include media-breakpoint-down(layout-flip) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 25px;
    padding: 25px 24px;
  }

  &__icon {
    width: 50px;
    margin-right: 8px;
    flex-shrink: 0;

    @include media-breakpoint-up(layout-flip) {
      width: 20px;
      margin-right: 10px;
    }
  }

  .content {
    flex-shrink: 0;
    flex-grow: 1;

    @include media-breakpoint-down(layout-flip) {
      order: 2;
    }

    h1 {
      max-width: 720px;
      margin: 0;
      font-weight: 600;
      font-size: 50px;
      line-height: 64px;
      color: $green-dark;

      @include media-breakpoint-down(lg) {
        max-width: 360px;
        margin: 0 0 2px;
        font-size: 40px;
      }

      @include media-breakpoint-down(layout-flip) {
        max-width: none;
        margin: 0 0 12px;
        font-size: 33px;
        line-height: 40px;
        letter-spacing: 0.25px;
      }
    }

    p {
      max-width: 577px;
      margin: 0;
      font-size: 24px;
      color: $green-dark;
      font-weight: 400;
      font-size: 20px;

      @include media-breakpoint-down(lg) {
        margin: 0 0 0.5px;
        font-size: 18px;

        &:last-of-type {
          margin: 0 0 34.5px;
        }
      }

      @include media-breakpoint-down(layout-flip) {
        max-width: none;
        margin: 0 0 12px;
        font-size: 16px;
        line-height: 24px;

        &:last-of-type {
          margin: 0 0 25px;
        }
      }
    }

    .search {
      display: flex;
      max-width: 600px;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        max-width: 328px;
      }

      @include media-breakpoint-down(layout-flip) {
        max-width: none;
      }

      .searchInput {
        margin: 0 15px 0 0;

        @include media-breakpoint-down(lg) {
          margin: 0 0 14px;
        }

        @include media-breakpoint-down(layout-flip) {
          margin: 0 0 10px;
        }
      }

      button {
        flex-shrink: 0;
        width: 250px;
        white-space: nowrap;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
  }

  .video {
    position: relative;
    max-width: 620px;
    width: 100%;
    height: 100%;

    @include media-breakpoint-down(md) {
      max-width: 502px;
    }

    @include media-breakpoint-down(layout-flip) {
      max-width: 565px;
    }

    video {
      min-height: 0;
      max-height: none;
    }
  }

  .featuredIcons {
    padding: 50px 0 40px;
    background-color: #E5F0FF;

    .slider {

      @include layout-flip {
        padding: 0 40px 60px 0;
      }
    }

    @include layout-flip {
      padding: 25px 0 20px;
    }
  }

  .featuredIconTitle {
    margin: 0 0 60px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
    color: #000000;

    @include layout-flip {
      margin: 0 0 36px;
      font-size: 33px;
    }
  }

  .logotypes {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 1200px) {
      margin: 0;
      padding: 0 0 30px;
      scrollbar-width: thin;
      scrollbar-color: #3ceac1 #ffffff;

      &::-webkit-scrollbar {
        height: 10px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        @include layout-flip {
          display: none;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: #3ceac1;
        border-radius: 20px;
        width: 50px;
      }
    }

    span svg {
      height: 60px;
      flex-grow: 0;
      flex-shrink: 0;

      @media (max-width: 1200px) {
        margin: 0 -10px 0 40px;
      }
    }

    svg {
      flex-grow: 0;
      flex-shrink: 0;
      height: 50px;
      overflow: auto;

      &:not(:first-of-type) {
        margin: 0 0 0 90px;
      }

      @media (max-width: 1440px) {
        height: 50px;
      }

      @media (max-width: 1200px) {
        height: 50px;

        & + svg {
          margin: 0 -10px 0 40px;
        }
      }

      @include layout-flip {
        width: 100%;
        margin: 0;
      }
    }

    @include layout-flip {
      margin: 0 0 50px;
    }

    @media (max-width: 600px) {
      margin: 0 0 0 50px;
      padding: 0;

      img {
        transform: scale(3.5);
      }
    }
  }
}
.iconsSlider {
  @media (max-width: 600px) {
    :global .swiper-wrapper {
      margin: 0 0 0 -30px;
    }
  }
}

.bottomSBtn {
  margin-top: 10px !important;
  width: 100% !important;
}