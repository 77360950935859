@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.root {
	padding: 24px 48px;

	@include media-breakpoint-down(layout-flip) {
		align-items: center;
	}

	@include media-breakpoint-down(sm) {
		align-items: end;
		padding: 24px 0 0;
	}

	.continueSurveyModal {
		position: relative;
		max-width: 664px;
		width: 100%;
		padding: 60px;
		background: $white;
		border-radius: 15px;

		@include media-breakpoint-down(sm) {
			padding: 50px 32px 32px;
			border-radius: 30px;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 15px;
				transform: translateX(-50%);
				width: 12%;
				height: 2px;
				background: #A7B3CE;
				border-radius: 100vmax;
			}
		}

		h1 {
			margin: 0 0 40px;
			text-align: center;
			font-weight: 600;
			font-size: 30px;
			line-height: 38px;

			@include media-breakpoint-down(sm) {
				margin: 0 0 20px;
				font-size: 22px;
				line-height: 28px;
				letter-spacing: 0.25px;
			}
		}

		p {
			margin: 0 0 40px;
			text-align: center;
			font-size: 16px;
			line-height: 19px;
			color: $gray-700;

			@include media-breakpoint-down(sm) {
				margin: 0 0 50px;
				letter-spacing: -0.01em;
			}
		}

		.buttons {
			display: flex;
			gap: 20px;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
				gap: 10px;
			}

			button {
				width: 100%;
				padding: 18.5px 37px;
			}
		}
	}
}
