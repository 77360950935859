@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.card {
	border-radius: 10px;
	border: 1px solid #21816A;
	background: #FAFAFA;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}

	.icon {
		width: 44px;
		height: 44px;
		margin: auto 0;
		background-color: #defaf6;
		border: 1px solid #3ceac1;
		border-radius: 50px;
		flex-shrink: 0;
		margin-right: 6px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-down(layout-flip) {
			margin-bottom: 24px;
		}
	}

	.cell {
		flex: 1;
		margin: 0 auto;
		padding: 0 6px;

		@media (max-width: 900px) {
			padding: 0;

			&:not(:last-of-type) {
				margin-bottom: 24px;
			}
		}

		@include media-breakpoint-down(layout-flip) {
			margin: 0;
			max-width: none;
		}
	}

	.caption {
		margin-bottom: 4px;
		font-weight: 500;
		font-size: 12px;
		text-transform: uppercase;
		line-height: 14px;
		color: #68768b;

		@include media-breakpoint-down(layout-flip) {
			white-space: initial;
		}
	}

	.text {
		margin: 0;
		padding: 0;
		font-size: 16px;
		line-height: 19px;
		color: #1f342f;
	}

	.edit,
	.remove {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 24px;
		width: 24px;
		height: 24px;
		margin: 1rem 1rem;
		padding: 0;
		border: none;
		background: none;
	}

	.edit {
		@include media-breakpoint-down(layout-flip) {
			position: absolute;
			top: 28px;
			right: 50px;

			&--only-btn {
				right: 18px;
			}
		}
	}

	.arrow {
		margin: auto 0;
		flex: 0 0 19px;

		@include clean-btn;
		width: 19px;
		height: 25px;

		@include media-breakpoint-down(layout-flip) {
			position: absolute;
			top: 28px;
			right: 22px;
		}

		svg {
			width: 100%;
			height: 100%;

			path {
				stroke: $green-dark;
			}
		}
	}
}
