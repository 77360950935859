.btn {
  &-square {
    @include sizeElement(44px);

    min-height: auto !important;
    min-width: auto !important;
    font-size: 0 !important;
    border-radius: 6px !important;
    padding: 5px !important;
  }

  &-back {
    background-image: url("../../assets/icons/arrow-back.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
  }

  &-edit {
    @include sizeElement(40px);
    @include inlineFlexCenter;

    flex-shrink: 0;
    background-color: $primary;
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba($primary, 0.31);
    border: none;
    padding: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($primary, 10%);
    }
  }

  &-with-checkmark {
    justify-content: flex-start !important;
    padding-right: 40px !important;

    @include media-breakpoint-up(xxs) {
      padding-right: 50px !important;
    }

    &:after {
      content: '\e901';
      font-family: icomoon;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      z-index: 1;
      font-weight: 400;
    }
  }
}