@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.wrapper {
  padding: 0;
}

.listing {
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
  z-index: 3;

  @include media-breakpoint-down(xl) {
    padding: 0 24px;
  }

  @include media-breakpoint-down(md) {
    padding: 120px 24px 0;
  }

  @include media-breakpoint-down(layout-flip) {
    padding: 94px 24px 0;
  }
}

.listingHeader {
  font-size: 30px;
  margin: 0px 0 10px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin: 50px 0 16px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }
}

.listingResults {
  font-size: 20px;
  line-height: 24px;
  color: $gray-700;
  margin-bottom: 40px;

  @media (max-width: 992px) {
    margin: 0 0 16px;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
}

.listingFilter {
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 16px 24px 60px;
    overflow: auto;
    background: $white;
  }
}

.listingFilterRow {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    flex-wrap: nowrap;
  }
}

.bottom {
  margin: 0 0 42px;
  overflow: auto;

  @media (max-width: 992px) {
    margin: 16px 0 20px;
  }
}

.listingFilterSelect {
  max-width: 256px;
  width: auto;
  margin-right: 16px;
  margin-bottom: 16px;

  @media (max-width: 992px) {
    max-width: 100%;
    margin: 0;

    label {
      display: none;
    }
  }
}

.listingFilterClear {
  background: none;
  font-size: 17px;
  line-height: 20px;
  text-decoration: underline;
  color: $teal-dark;
  outline: none;
  border: none;
}

.listingFilterTag {
  margin-right: 24px;
  margin-bottom: 16px;
  white-space: nowrap;

  @media (max-width: 992px) {
    padding: 9px 8px;
    margin-right: 8px;

    span {
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.01em;
    }
  }
}

.noAvailable {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    width: 40px;
    height: 40px;
    margin: 0 16px 0 0;
  }
}

.pointerForCertified {
  padding: 40px 0;

  @include media-breakpoint-up(md) {
    padding: 65px 0;
  }

  &__item {
    @include flexCenter;

    position: relative;
    font-size: 20px;
    font-weight: 500;
    min-height: 56px;
    border: 2px solid;
    text-align: center;
    padding: 10px 15px;
    border-radius: 12px;
    margin: 14px 0;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      min-height: 80px;
      border-radius: 20px;
      margin: 23px 0;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 5;
      display: block;
    }
  }

  &__itemCertified {
    background-color: $light-mint;
    border-color: $primary;
    color: $teal-dark;

    @include media-breakpoint-down(xs) {
      margin-bottom: 8px;
    }

    @include media-breakpoint-up(xs) {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:after {
      @include borderArrow(28, 14, $primary, t);

      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);

      @include media-breakpoint-up(md) {
        @include borderArrow(45, 23, $primary, t);
      }
    }
  }

  &__itemNotCertified {
    background-color: #E9EBF8;
    border-color: #808188;
    color: var(--black_text, #1F342F);

    @include media-breakpoint-down(xs) {
      border: none;
      margin-top: 0;
    }

    @include media-breakpoint-up(xs) {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: $gray-160;
    }

    &:after {
      @include borderArrow(28, 14, #E9EBF8, b);

      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);

      @include media-breakpoint-up(xs) {
        @include borderArrow(28, 14, $gray-200, b);
      }

      @include media-breakpoint-up(md) {
        @include borderArrow(45, 23, $gray-200, b);
      }
    }
  }
}