@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.field {
	&.size-sm {
		.inputHolder {
			height: $input-sm-height;
			padding: $input-sm-padding;
		}
	}

	&.inputOptional > div {
		&:before {
			content: attr(data-optional);
			position: absolute;
			padding-left: 8px;
			right: 16px;
			bottom: 18px;
			font-weight: 500;
			font-size: 11px;
			line-height: 24px;
			color: #a7b2cd;
			background: $white;
		}
	}

	.description {
		color: $gray-700;
		margin: 8px 0 0;
		font-size: 13px;

		&.right {
			text-align: end;
		}

		&.left {
			text-align: start;
		}
	}

	&.disabled {
		.inputHolder {
			background: #efefef4d;
		}

		.input {
			background: transparent;
			border-color: transparent;
		}
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.label {
	display: block;
	margin-bottom: 16px;
	font-size: 16px;
	line-height: 1.1;
	color: #1f342f;
}

.inputHolder {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: calc($btn-height - 10px);
	padding: $input-padding;
	background-color: $input-background-color;
	border: $input-border-width solid $input-border-color;
	border-radius: $input-border-radius;
	transition: $input-transition;

	@include media-breakpoint-up(md) {
		height: $input-height;
	}

	&:hover,
	&:focus,
	&.active {
		border-color: $input-hover-border-color;
	}

	&.error {
		border-color: $error;

		.inputAdornment,
		.inputAdornmentLeft,
		.hashtag {
			border-color: $error;
		}
	}
}

.input {
	@include placeholder {
		color: $input-placeholder-color;
	};

	display: block;
	width: 100%;
	border: none;
	border-radius: 0;
	padding: 0;
	outline: none;
	background-color: $input-background-color;
	font-size: $input-font-size;
	line-height: $input-line-height;
	color: $input-color;

	&:disabled {
		color: rgba($green-dark, 0.65);
	}
}

.field .input[readonly] {
	color: $gray-700;
}
