$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icomoon-chat: "\e90b";
$icomoon-phone: "\e90c";
$icomoon-video: "\e90d";
$icomoon-round-6-years: "\e907";
$icomoon-round-thumbs-up: "\e908";
$icomoon-round-user: "\e909";
$icomoon-round-evaluation: "\e90a";
$icomoon-round-video: "\e904";
$icomoon-round-phone: "\e905";
$icomoon-round-message: "\e906";
$icomoon-info-light: "\e903";
$icomoon-remove: "\e900";
$icomoon-checkmark: "\e901";
$icomoon-info: "\e902";

