@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";
@import "../../../../components/UIKit/Button/Button.module.scss";

.sectionOffers {
  padding: 40px 0;
  flex-grow: 1;
  background: linear-gradient(180deg, #E5FBF8 0%, #ECFDF9 100%);

  @include media-breakpoint-up(xxs) {
    padding: 60px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 0 60px;
  }

  &__cardsRow {
    > * {
      $items: 18;
      $item: 1;

      @while $items > 0 {
        &:nth-child(#{$item}) {
          z-index: $items;
        }

        $items: $items - 1;
        $item: $item + 1;
      }
    }
  }
}

.cardOffer {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid $gray-200;
  border-radius: 20px;
  background-color: $white;
  margin-bottom: 20px;

  @include media-breakpoint-up(xxs) {
    width: calc(($container-normal-width - $grid-gutter-width * 2.5) / 2.5);
  }

  @include media-breakpoint-up(xs) {
    margin-bottom: 40px;
  }

  &.cardOffer__active {
    .cardOffer__topContent,
    .cardOffer__bottomContentInner {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__topContent {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px 15px 0;
    text-align: center;

    @include media-breakpoint-up(xs) {
      padding: 30px 25px 0;
    }

    @include media-breakpoint-up(md) {
      padding: 40px 36px 0;
    }

    &:after {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      display: block;
      pointer-events: none;
      z-index: 10;
      border-radius: 20px 20px 0 0;
      border-width: 4px 4px 0 4px;
      border-color: $primary;
      border-style: solid;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }

  &__iconBlock {
    @include sizeElement(80px);
    @include inlineFlexCenter;

    border-radius: 50%;
    border: 2px solid $primary;
    margin: 0 auto 20px;
    flex-shrink: 0;

    @include media-breakpoint-up(md) {
      @include sizeElement(120px);

      margin-bottom: 30px;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 60%;
  }

  &__titleHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    flex-grow: 1;
  }

  &__subTitlesList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__subTitle {
    color: $gray-700;
    padding: 0 8px;
    margin: 2px 0;
    line-height: 1.1;

    &:not(:last-child) {
      border-right: 1px solid currentColor;
    }
  }

  &__bottomContent {
    position: relative;
    min-height: 260px;
  }

  &__bottomContentInner {
    /*position: absolute;*/
    top: 0;
    right: -2px;
    left: -2px;
    min-height: calc(100% + 2px);
    display: flex;
    flex-direction: column;
    background-image: url("../../../../assets/images/backgrounds/cardOffer-bottomContentBg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    background-color: $white;
    border-radius: 0 0 20px 20px;
    padding: 60px 15px 25px;
    border-width: 0 2px 2px 2px;
    border-color: $gray-200;
    border-style: solid;

    @include media-breakpoint-up(xs) {
      padding: 60px 25px 30px;
    }

    @include media-breakpoint-up(md) {
      padding: 60px 36px 36px;
    }

    &:after {
      content: "";
      /*position: absolute;*/
      left: -2px;
      right: -2px;
      bottom: -2px;
      top: 0;
      display: block;
      border-radius: 0 0 20px 20px;
      border-width: 0 4px 4px 4px;
      border-color: $primary;
      border-style: solid;
      z-index: 10;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }

  &__description {
    position: relative;
    padding-left: 35px;

    &:before {
  /*	@include sizeElement(24px);*/
    @include sizeElement(15px);
      @include inlineFlexCenter;

      /*content: "\e901";*/
    content: " ";
      position: absolute;
    margin-top:3px;
      top: 0;
      left: 0;
      font-family: icomoon;
      background-color: $primary;
      color: $white;
      border-radius: 50%;
      font-size: 16px;
    }
  }

  &__btnBlock {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 25px;
  }
}

.checkboxLikeButton {
  display: block;

  &__input {
    @include visuallyHidden;

    &:checked {
      + .checkboxLikeButton__block {
        .checkboxLikeButton__notSelectedYetBlock {
          display: none;
        }

        .checkboxLikeButton__selectedBlock {
          display: block;
        }
      }
    }

    &:disabled {
      + .checkboxLikeButton__block {
        .checkboxLikeButton__notSelectedYetBlock {
          background-color: $gray-150;
          border-color: $gray-150;
          cursor: not-allowed;
        }
      }
    }

    &:checked:disabled {
      + .checkboxLikeButton__block {
        pointer-events: none;

        .checkboxLikeButton__notSelectedYetBlock {
          display: none;
        }

        .checkboxLikeButton__selectedBlock {
          display: none;
        }

        .checkboxLikeButton__selectedByDefaultBlock {
          display: inline-flex;
        }
      }
    }
  }

  &__block {
    display: block;
    cursor: pointer;
  }

  &__notSelectedYetBlock {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-sm;

    width: 100%;
  }

  &__selectedBlock {
    position: relative;
    padding-right: calc($btn-sm-height + 8px);
    display: none;

    &:hover {
      .checkboxLikeButton__selectedBlockUncheck {
        background-color: $white;
        border-color: darken($pink, 12.5%);
        color: $red;
      }
    }
  }

  &__selectedBlockText {
    @extend .btn;
    @extend .btn-outline-green-light;
    @extend .btn-sm;

    width: 100%;
    pointer-events: none;
  }

  &__selectedBlockUncheck {
    @include sizeElement($btn-sm-height);
    @extend .btn;
    @extend .btn-outline-red-light;
    @extend .btn-sm;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    min-width: auto;
    padding: 5px;
  }

  &__selectedByDefaultBlock {
    @extend .btn;
    @extend .btn-green-light;
    @extend .btn-sm;

    width: 100%;
    display: none;
  }
}

.reverseMobile {
  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
}
