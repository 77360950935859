@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.title {
  margin-bottom: 10px;
  text-align: center;
}

.error_text {
  margin-bottom: 40px;
  text-align: center;
}

.xcircle {
  margin-bottom: 50px;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;

  color: #26d5b6;
  cursor: pointer;
}
