@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.radio {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 16px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: $gray-700;
	background: $white;
	border: 1px solid $gray-200;
	border-radius: 4px;
	cursor: pointer;

	&.checked {
		color: $green-dark;
		background: $light-mint;
		border: 1px solid $primary;
	}

	input {
		appearance: none;
		width: 24px;
		height: 24px;
		border: 1px solid $gray-200;
		border-radius: 100vmax;

		&:checked {
			border: 6px solid $primary;
		}
	}
}
