@import "styles/abstracts/variables";
@import "styles/abstracts/mixins";

.sectionTherapistProfile {
  padding: 60px 0;

  @include media-breakpoint-down(md) {
    padding: 10px 0;
  }

  &__content {
    padding-top: 10px;

    @include media-breakpoint-up(layout-flip) {
      display: flex;
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
      padding-top: 30px;

    }
  }

  &__leftSide {
    flex: 0 0 auto;
    width: 140px;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      width: 160px;
    }

    @include media-breakpoint-up(lg) {
      width: 215px;
    }
  }

  &__middle {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(layout-flip) {
      padding: 0 30px;
    }

    @include media-breakpoint-up(md) {
      padding: 0 40px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 55px;
    }
  }

  &__rightSide {
    flex: 0 0 auto;
    width: 290px;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      width: 335px;
    }
  }

  .back {
    @include media-breakpoint-down(layout-flip) {
      margin-bottom: 20px;
    }
  }
}
